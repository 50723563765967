import * as React from "react";
import { navigate } from "gatsby";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import ProfilePageLayout from "../components/profile/ProfilePageLayout/ProfilePageLayout";
import { useAppSelector } from "../hooks";
import { isLoggedInSelector } from "../redux/slices/Member/member";
import {
  StyledContainer,
  ProfileHeader,
} from "../pageHelpers/Account/AccountHelpers";

const Account: React.FC = () => {
  const memberLoggedIn = useAppSelector(isLoggedInSelector); //auth0Client.isAuthenticated();

  React.useEffect(() => {
    if (!memberLoggedIn) {
      navigate("/"); //navigate("/travel-pass");
    }
  }, []);

  return (
    <Layout>
      <SEO title={`Profile Page`} />
      <ProfileHeader />
      <StyledContainer>
        <ProfilePageLayout />
      </StyledContainer>
    </Layout>
  );
};

export default Account;
